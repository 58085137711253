// 
// general.scss
//

html {
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 0;
}
body {
    min-height: 100%;
    overflow-x: hidden;
 	background-color:white;
 	    margin: 0;
    padding: 0;
}
.container {
	padding-bottom:16vh;
}

.div-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

i{
	cursor:pointer;
}

td{
	word-break:break-all; 
	white-space:pre-line; 
}

table.dataTable thead {
	background-color:#f9f9f9; 
}
th{
	font-size:15px;
	background-color:#f9f9f9; 
}
modal-body li.nav-item{
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.required{
	color:#c31b1b;
	font-weight:bold;
}
input{
	font-size:15px !important;
}
select{
}
label{
	font-weight:bold;
	font-size:14px !important;
	height:30px !important;
	//background-color:#f9f9f9; 
	
}
div.dataTables_scrollHead table.dataTable tr{
	height:50px !important; 
}

.tr-group-level-1{
	//background-color:#fffbfb; 
    font-weight: bold;
    height:45px !important; 
    
}
ul.pagination{
	margin-top:30px !important;
}

.tr-group-level-2{
    padding-left: 2em;
    cursor:pointer;
}

.right{
	float:right;
}

.left{
	float:left;
}

#tbEstmReqDtlList td{
	padding: 5px 5px 5px 5px;
	vertical-align: middle;
}
#tbEstmReqDtlList input[type="checkbox"]{
	width:13px;
	height:20px;
}
.form-table div.col-3{
	padding-left:0px;
}
#tbInquiryProdList td{
	word-break:break-all !important; 
	white-space:pre-line !important; 
}	
.form-table{
}
.form-table td.form-label{
	font-family: Nunito,sans-serif;
    font-size: 14px;
    font-weight: bold;
    background-color:#e3eaef;
    padding-left:10px;
}

.form-table td{
	border:1px solid #d7d5d5;
	vertical-align:center;
    text-align: left;
     padding: 5px 5px 5px 5px;
}
.form-table button{
	height:100%;
}
.span-header{
	font-size:20px;
	font-weight:bold;
}

div.div-header{
	margin-top:30px;
	margin-bottom:10px;
}
div.div-content{
	margin-top:0px;
	margin-bottom:30px;
}
div.div-header > span{
	font-family:Arial Black;
	font-size:28px;
	font-weight:bold;
	color:#1f5ba2;
}

div.container-home{
	width:100%;
}
div.home-main {
	background-image: url("../images/index/home.png");
	background-size: cover;
	height:120vh;
}
div.home-main-text{
	height:50vh;
	text-align: center;
	line-height:50vh;
}
div.home-main-text > img{
    max-width:100%; max-height:100%;
    display:inline-block;
    vertical-align:middle;
}

.height-100{
	height:10vh;
}

.h-100{
	height:100%;
}


span.home-main-text-span{
}

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }
}

div.editor-read-header{
	margin-top:30px;
	margin-bottom:30px;
}

div.editor-read-header > span{
	font-family:Arial Black;
	font-size:28px;
	font-weight:bold;
	color:#1f5ba2;
}

div.editor-read-content{
	height:100%;
	border-bottom:1px solid #d9d9d9;
}

div.qq-file-info{
	height:30px !important;
}
div.pnp-footer-content{
	justify-content:center;
	align-items:center;
	height:100%;
}
div.footer-content-1{
	margin-right:60px;
}
div.footer-content-2{
	margin-left:60px;
}
.pnp-footer{
	height:15vh;
		align-items:center;
}

@media ( min-width: 768px ) {
	div.top-bar{
		height:13vh;
	
	}
	.nav-pnp-menu{
		height:13vh;
		border-bottom:1px solid #d9d9d9;
	}
}

li.breadcrumb-item.active::after{
	content:'' !important;
}
li.breadcrumb-item::after{
	content:'/' !important;
	font-size: 1rem;
}

li.breadcrumb-item{
	padding-left:0px !important;
}

li.breadcrumb-item > a{
	margin-right:10px;
	margin-left:0px;
}

li.breadcrumb-item.active::before{
	content:'' !important;
}
li.breadcrumb-item::before{
	content:'' !important;
	display:block;
}

.dataTables_wrapper table thead#tbCmpnyListHead{
    display:none;
}


.datepicker td, .datepicker th{
  	font-size: smaller;
  	width: 32px;
  	height: 22px;
  }
#tbEstmReqDtlList{
	border:1px solid #e3eaef;
}
#tbMailAttachList{
	border:1px solid #e3eaef;
}
  

div.min-height{
	min-height:55vh !important;
}
  
  
div.sample-header{
	margin-top:50px;
	margin-bottom:30px;
}


/* ===== About Page ===== */
span.pnp-about{
	font-family: "Arial Black", sans-serif;
	font-size:45px ; 
	color:#1f5ba2 !important;
	font-weight:bold;
}

span.pnp-safety{
	font-family:Arial Nova;
	font-size:32px ; 
	font-weight:bold;
	color:#1f5ba2 !important;
}

span.world-best{
	font-family:Arial Nova; 
	font-size:22px ;
	font-weight:bold ; 
	color:#55b0fa !important;
}
  
p.img-description {
	font-family:Arial Nova; 
	font-size:17px ; 
	color:#454545 !important; 
	position:relative; 
}

.img-site {
  width: 100%;
  height: 170px;
}

@media ( min-width: 1001px ) {
	p.description{
		font-family:Arial Nova; 
		font-size:18px;
		color:#949292 !important;
		font-stretch: expanded;
		display:block;
		margin-bottom:60px;
	}
	p.m-description{
		display:none;
	}
}

@media ( max-width: 1000px ) {
	.container {
		padding-bottom:25vh;
	}
	#tbNoticeList{
		margin-left:10px;
		margin-right:10px;
	}
	div.div-header > span{
		font-family:Arial Black;
		font-size:10px;
	}
	div.pnp-footer-content{
		justify-content:center;
		padding-top:30px;
	}
	div.footer-content-1{
		margin-right:0px;
		text-align: center;
	}
	div.footer-content-2{
		margin-left:0px;
		text-align: center;
	}
	.pnp-footer{
		height:30vh;
	}
	p.navbar-toggle-icon{
		margin-bottom:0px !important;
	}
	.navbar-toggler p{
		margin-bottom:0px !important;
	}
	p.img-description {
		padding:0px 0px 0px 0px !important;
	}
	
	.img-site {
		padding:0px 0px 0px 0px !important;
		height: 110px !important;
	}
	p.description{
		display: none;
	}
	p.m-description{
		display: block;
		font-family:Arial Nova; 
		font-size:16px;
		color:#949292 !important;
		font-stretch: expanded;
		margin-bottom:40px;
		
	}
	div.home-main {
		background-image: url("../images/index/home.png");
		background-size: cover;
		height:90vh;
	}
	ul.auth-menu{
		display:none;
	}
	div.pnp-menu {
		margin-top:10px;
		margin-bottom:10px;
		margin-left:20px;
	}
	span.world-best{
		font-size:18px !important;
	}
	span.pnp-about{
		font-size:32px !important; 
	}
	
	span.pnp-safety{
		font-size:28px !important; 
	}
}
div.min-height{
	min-height:60vh !important;
}

#noticeContent{
	min-height:45vh !important;
}
