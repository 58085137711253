div.pnp-menu ul{ 
	float:left;
}
div.pnp-menu a{ 
	color:#595959; 
	font-size:17px;
	font-weight:bold;
}
span.userInfo{ 
	color:white; 
	font-size:15px;
	background-color:#595959;
	border-radius: 20em;
	font-family:Arial Nova;
	width:150%;
}
div.pnp-menu a:hover{ 
	color:red !important;
}
.nav-item{
	margin-left:15px !important;
	margin-right:15px !important;
}
    .topbar-right-menu {
        li {
            float: left;
        }
        .nav-link {
            padding: 0;
            color: $gray-600;
            min-width: 32px;
            display: block;
            text-align: center;
            margin: 0 8px;
        }
    }
    .profile-dropdown {
    width: 170px;
    i {
        vertical-align: middle;
        margin-right: 5px;
    }
    span {
        vertical-align: middle;
    }
}
    
.nav-user {
    padding: 17px 20px 17px 57px !important;
    text-align: left !important;
    position: relative;
    border-width: 0 1px;
    min-height: 70px;

    .account-user-avatar {
        position: absolute;
        top: 19px;
        left: 15px;

        img {
            height: 32px;
            width: 32px;
        }
    }
    .account-position {
        display: block;
        font-size: 12px;
        margin-top: -3px;
    }
    .account-user-name {
        display: block;
        font-size: 15px;
    }
}
.rounded-circle {
  border-radius: 50% !important;
}
.dropdown-menu a{
	font-size: 14px !important;
	font-weight:bold;
}
.arrow-none {
    &:after {
        display: none;
    }
}
.selected-menu{
	color:red !important;
	//text-decoration: underline;
	//text-underline-position: under;
	//padding-bottom:13px;

}
