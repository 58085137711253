.text-bold {
    font-weight:bold;
}
.font-8 {
    font-size:8px !important;
}
.font-9 {
    font-size:9px !important;
}
.font-10 {
    font-size:10px !important;
}
.font-11 {
    font-size:11px !important;
}
.font-12 {
  	font-size:12px !important;
}
.font-13 {
    font-size:13px !important;
}
.font-14 {
    font-size:14px !important;
}
.font-15 {
    font-size:15px !important;
}
.font-16 {
    font-size:16px !important;
}
.font-16 {
    font-size:16px !important;
}
span.content-header{
	font-family:Arial Black;
	font-size:28px;
	font-weight:bold;
	color:#1f5ba2;
}

span.content-html-header{
	font-family:Arial Black;
	font-size:28px;
	font-weight:bold;
	color:#1f5ba2;
}

span.content-html-body{
	font-family:Arial Black;
	font-size:28px;
	font-weight:bold;
	color:#1f5ba2;
}

